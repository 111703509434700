<template>
  <ValidationObserver
    ref="refFormObserver"
    #default="{ invalid }"
  >
    <BModal
      id="modal-edit-bank-accounts"
      :title="$t('agency.bankAccountsList.editBankAccount')"
      title-class="text-airline font-medium-4 font-weight-bolder"
      centered
      body-class="px-75"
      size="lg"
      no-close-on-backdrop
      @show="showHandle"
    >
      <template #modal-footer="{ cancel }">
        <BButton
          variant="outline-secondary"
          class="center rounded-pill"
          @click="cancel()"
        >
          {{ $t('bankAccount.cancel') }}
        </BButton>

        <BButton
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="btn-gradient border-right-0"
          pill
          :disabled="invalid"
          @click="handleEdit"
        >
          <div class="d-flex-center px-25">
            {{ $t('bankAccount.edit') }}
          </div>
        </BButton>
      </template>
      <IAmOverlay :loading="loading">
        <div>
          <!-- ANCHOR Branch -->
          <ValidationProvider
            #default="validationContext"
            :name="$t('bankAccount.branch')"
            rules="required"
          >
            <BFormGroup label-for="branch">
              <template #label>
                <div class="text-nowrap">
                  {{ $t('bankAccount.branch') }}
                  <span class="text-danger">(*)</span>
                </div>
              </template>
              <BFormInput
                id="branch"
                v-model="dataToEdit.branch"
                :state="
                  getValidationState(validationContext) === false ? false : null
                "
                lazy-formatter
                :formatter="removeAccentsUpperCaseFormatter"
                :placeholder="$t('bankAccount.ph.branch')"
              />
              <BFormInvalidFeedback>
                {{ validationContext.errors[0] }}
              </BFormInvalidFeedback>
            </BFormGroup>
          </ValidationProvider>
        </div>

        <template v-if="dataToEdit.isAuto">
          <!-- ANCHOR identificationNumber -->
          <ValidationProvider
            #default="validationContext"
            :name="$t('bankAccount.identificationNumber')"
            rules="required"
          >
            <BFormGroup label-for="identificationNumber">
              <template #label>
                <div class="text-nowrap">
                  {{ $t('bankAccount.identificationNumber') }}
                  <span class="text-danger">(*)</span>
                </div>
              </template>
              <BFormInput
                id="identificationNumber"
                v-model="dataToEdit.identificationNumber"
                :state="
                  getValidationState(validationContext) === false ? false : null
                "
                lazy-formatter
                :formatter="removeAccentsUpperCaseFormatter"
                :placeholder="$t('bankAccount.ph.identificationNumber')"
              />
              <BFormInvalidFeedback>
                {{ validationContext.errors[0] }}
              </BFormInvalidFeedback>
            </BFormGroup>
          </ValidationProvider>

          <!-- ANCHOR phoneNumber -->
          <ValidationProvider
            #default="validationContext"
            :name="$t('bankAccount.phoneNumber')"
            rules="required"
          >
            <BFormGroup label-for="phoneNumber">
              <template #label>
                <div class="text-nowrap">
                  {{ $t('bankAccount.phoneNumber') }}
                  <span class="text-danger">(*)</span>
                </div>
              </template>
              <BFormInput
                id="phoneNumber"
                v-model="dataToEdit.phoneNumber"
                :state="
                  getValidationState(validationContext) === false ? false : null
                "
                lazy-formatter
                :formatter="removeAccentsUpperCaseFormatter"
                :placeholder="$t('bankAccount.ph.bankAccountName')"
              />
              <BFormInvalidFeedback>
                {{ validationContext.errors[0] }}
              </BFormInvalidFeedback>
            </BFormGroup>
          </ValidationProvider>
        </template>

        <div v-if="!dataToEdit.isAuto">
          <!-- ANCHOR isDefault -->
          <ValidationProvider
            #default="validationContext"
            :name="$t('bankAccount.isDefault')"
            rules="required"
          >
            <BFormGroup label-for="isDefault">
              <BFormCheckbox
                id="isDefault"
                v-model="dataToEdit.isDefault"
                :state="
                  getValidationState(validationContext) === false ? false : null
                "
                lazy-formatter
                switch
                :placeholder="$t('bankAccount.isDefault')"
              >
                <div class="text-nowrap">
                  {{ $t('bankAccount.isDefault') }}
                </div>
              </BFormCheckbox>
              <BFormInvalidFeedback>
                {{ validationContext.errors[0] }}
              </BFormInvalidFeedback>
            </BFormGroup>
          </ValidationProvider>
        </div>

        <div v-if="bankSelected && bankSelected.isAuto">
          <!-- ANCHOR isAuto -->
          <ValidationProvider
            #default="validationContext"
            :name="$t('bankAccount.isDefault')"
            rules="required"
          >
            <BFormGroup label-for="isAuto">
              <BFormCheckbox
                id="isAuto"
                v-model="dataToEdit.isAuto"
                :state="
                  getValidationState(validationContext) === false ? false : null
                "
                lazy-formatter
                switch
                :placeholder="$t('bankAccount.isAuto')"
              >
                <div class="text-nowrap">
                  {{ $t('bankAccount.isAuto') }}
                </div>
              </BFormCheckbox>
              <small class="text-danger">
                {{
                  $t('modal.setAutoBank', {
                    fee: formatCurrency(bankSelected.paymentTxsFee),
                  })
                }}
              </small>
              <BFormInvalidFeedback>
                {{ validationContext.errors[0] }}
              </BFormInvalidFeedback>
            </BFormGroup>
          </ValidationProvider>
        </div>
      </IAmOverlay>
    </BModal>
  </ValidationObserver>
</template>
<script>
import {
  computed, ref, toRefs, watch,
} from '@vue/composition-api'
import {
  BButton,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BModal,
} from 'bootstrap-vue'
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import { formatCurrency } from '@/@core/utils/filter'
import { getDifference } from '@/@core/utils/utils'
import { apiBankAccount } from '@/api'
import useToast from '@/libs/toastification/useToast'
import store from '@/store'

import formValidation from '@core/comp-functions/forms/form-validation'
import { removeAccentsUpperCaseFormatter } from '@core/comp-functions/forms/formatter-input'

export default {
  components: {
    BModal,
    BButton,
    BFormInvalidFeedback,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
  },
  setup(props, { emit }) {
    const { toastError, toastSuccess } = useToast()
    const { id } = toRefs(props)
    const bankListOptions = ref()
    const blankBankAccount = {
      bankId: '',
      branch: '',
      bankAccountNo: '',
      bankAccountName: '',
      identificationNumber: '',
      phoneNumber: '',
      label: '',
      isDefault: false,
      isAuto: false,
    }
    const item = ref(cloneDeep(blankBankAccount))
    const dataToEdit = ref(cloneDeep(blankBankAccount))
    const bankSelected = ref({ isAuto: false })

    const resetBankAccountDataToEdit = () => {
      dataToEdit.value = cloneDeep(item)
    }
    const { refFormObserver, getValidationState } = formValidation(
      resetBankAccountDataToEdit,
    )
    const loading = ref(true)
    async function handleEdit() {
      try {
        loading.value = true
        const payload = getDifference(dataToEdit.value, item.value)
        if (Object.keys(payload).length > 0 && payload.constructor === Object) {
          await apiBankAccount.updateBankAccount(dataToEdit.value.id, payload)
          // emit('fetch-bank-account')
          if (payload.isAuto) {
            emit('verify-bank-account', dataToEdit.value)
          } else {
            emit('fetch-bank-account')
          }
          this.$bvModal.hide('modal-edit-bank-accounts')
          toastSuccess('bank.updateSuccess')
        }
      } catch (error) {
        toastError(error)
      } finally {
        loading.value = false
      }
    }

    const bankListOptionsDefault = computed(
      () => store.getters['userStore/getListBank'],
    )

    function resetBankListOptions() {
      bankListOptions.value = cloneDeep(bankListOptionsDefault.value)
    }

    function openBankListHandle() {
      if (!bankListOptions.value) resetBankListOptions()
    }

    const searchBankListHandle = debounce(search => {
      if (search) {
        bankListOptions.value = bankListOptionsDefault.value.filter(
          item => item.code.toLowerCase() === search.toLowerCase()
            || item.shortName.toLowerCase().includes(search.toLowerCase())
            || item.name.toLowerCase().includes(search.toLowerCase()),
        )
      } else if (search === '') {
        resetBankListOptions()
      }
    }, 300)

    async function searchBankHolder() {
      if (
        dataToEdit.value.bankAccountNo
        && bankSelected.value
        && bankSelected.value.isAuto
      ) {
        const { data } = await apiBankAccount.getBankHolder(
          bankSelected.value.gateway,
          dataToEdit.value.bankAccountNo,
        )
        dataToEdit.value.bankAccountName = data.bankHolder
      }
    }

    function selectedBank(bank) {
      bankSelected.value = bank
    }

    async function loadBankAccount(id) {
      console.log('🚀 --- loadBankAccount --- id:', id)
      // get detail bank account
      const { data } = await apiBankAccount.getBankAccount(id)
      item.value = cloneDeep(data)
      dataToEdit.value = cloneDeep(data)
      bankSelected.value = cloneDeep(data.bank)
      openBankListHandle()
      loading.value = false
    }

    async function showHandle() {
      if (id.value) {
        loadBankAccount(id.value)
      }
    }
    watch(
      id,
      val => {
        loadBankAccount(id.value)
      },
      { deep: true },
    )

    return {
      dataToEdit,
      handleEdit,
      loading,
      refFormObserver,
      getValidationState,
      removeAccentsUpperCaseFormatter,
      showHandle,

      bankListOptions,
      openBankListHandle,
      searchBankListHandle,
      bankSelected,
      searchBankHolder,
      selectedBank,
      loadBankAccount,
      formatCurrency,
    }
  },
}
</script>

<style lang="scss" scoped>
#is-default-div ::v-deep {
  .custom-control-label {
    width: 100%;
    color: black;
  }
}
</style>

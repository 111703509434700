<template>
  <div>
    <IAmOverlay :loading="loading">
      <BCard body-class="py-0">
        <div class="d-flex-center justify-content-between mb-1">
          <h4>
            {{ $t('bankAccountsInfo') }}
          </h4>
          <BButton
            variant="primary"
            @click="handleAddBankAccount"
          >
            <div class="d-flex-center">
              <feather-icon
                icon="PlusIcon"
                size="18"
                class="mr-25"
              />
              {{ $t('bankAccount.addBankAccount') }}
            </div>
          </BButton>
        </div>

        <b-table-lite
          bordered
          responsive
          class="mb-1 rounded"
          thead-class="text-dark font-weight-bolder text-nowrap"
          :items="bankAccountsList"
          :fields="tableColumns"
        >
          <template
            v-for="column in tableColumns"
            #[`head(${column})`]="data"
          >
            <span
              :key="column"
              class="text-dark"
            >
              {{ $t(`bankAccount.${data.column}`) }}
            </span>
          </template>
          <template #cell(bankId)="data">
            <div class="d-flex-center justify-content-start">
              <BImg
                :src="resolveBankInfo(data.item.bankId).bankLogo"
                width="50"
              />
              <div>{{ resolveBankInfo(data.item.bankId).bankName }}</div>
            </div>
          </template>

          <template #cell(isDefault)="data">
            <BFormCheckbox
              :id="`isDefault-${data.index}`"
              v-model="data.item.isDefault"
              lazy-formatter
              switch
              :disabled="data.item.status != 'CONFIRMED'"
              :placeholder="$t('bankAccount.isDefault')"
              @click.native.prevent="handleSwitchDefault(data.item)"
            />
          </template>

          <template #cell(isAuto)="data">
            <div class="d-flex gap-1">
              {{ data.item.isAuto ? $t(`bankAccount.active`) : $t(`bankAccount.unActive`) }}
            </div>
          </template>

          <template #cell(status)="data">
            {{ $t(`bankAccount.${data.item.status}`) }}
          </template>

          <template #cell(action)="data">
            <div class="d-flex gap-2 align-items-center">
              <!-- ANCHOR btnUpdate. -->
              <b-button
                v-if="data.item.status == 'CONFIRMED' && !data.item.isAuto"
                id="btnUpdate"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="flat-info"
                class="py-50 px-75 rounded"
                size="md"
                @click="editBankAccount(data.item.id)"
              >
                <IAmIcon
                  icon="editOutline"
                  size="18"
                />
              </b-button>

              <b-button
                v-if="data.item.status == 'CONFIRMED' && !data.item.isDefault"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                title="Delete"
                variant="outline-danger"
                class="btn-icon"
                @click="confirmDelete(data.item, $event)"
              >
                <feather-icon
                  icon="TrashIcon"
                  size="16"
                  style="cursor: pointer"
                />
              </b-button>

              <b-button
                v-if="data.item.status != 'CONFIRMED'"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.modal-verify-bank-accounts
                title="verify"
                variant="outline-warning"
                class="btn-icon"
                @click="verifyBankAccount(data.item, $event)"
              >
                <feather-icon
                  icon="CheckCircleIcon"
                  size="16"
                  style="cursor: pointer"
                />
              </b-button>

              <div
                v-if="data.item.status === 'CONFIRMED' && data.item?.bank?.isAuto && !!data.item?.identificationNumber"
                :id="`isAuto-${data.index}`"
              >
                <BFormCheckbox
                  v-model="data.item.isAuto"
                  lazy-formatter
                  switch
                  :disabled="data.item.status != 'CONFIRMED' || !data.item?.bank?.isAuto"
                  :placeholder="$t('bankAccount.isAuto')"
                  @click.native.prevent="handleSwitchAuto(data.item, data.item.bank)"
                />

                <b-tooltip
                  :target="`isAuto-${data.index}`"
                  triggers="hover focus"
                  boundary="viewport"
                  variant="info"
                >
                  <div>{{ $t('bankAccount.isAuto') }}</div>
                </b-tooltip>
              </div>
            </div>
          </template>
        </b-table-lite>
      </BCard>
    </IAmOverlay>

    <ModalAddBankAccounts
      :agency-id="agencyId"
      @fetch-bank-account="fetchBankAccounts"
      @verify-bank-account="verifyBankAccount"
    />

    <ModalEditBankAccounts
      :id.sync="bankAccountId"
      @fetch-bank-account="fetchBankAccounts"
      @verify-bank-account="verifyBankAccount"
    />

    <ModalVerifyBankAccount
      :bank-account="bankAccount"
      @fetch-bank-account="fetchBankAccounts"
    />
  </div>
</template>

<script>
import { computed, onMounted, ref } from '@vue/composition-api'
import {
  BButton,
  BCard,
  BFormCheckbox,
  BImg,
  BTableLite,
  BTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { apiBankAccount } from '@/api'
import IAmIcon from '@/components/IAmIcon.vue'
import IAmOverlay from '@/components/IAmOverlay.vue'
import store from '@/store'

import {
  formatCurrency,
} from '@core/utils/filter'

import useToast from '@useToast'

import ModalAddBankAccounts from './ModalAddBankAccounts.vue'
import ModalEditBankAccounts from './ModalEditBankAccounts.vue'
import ModalVerifyBankAccount from './ModalVerifyBankAccount.vue'
import useBankAccounts from './useBankAccounts'

export default {
  components: {
    BCard,
    BTableLite,
    BButton,
    ModalAddBankAccounts,
    IAmOverlay,
    BFormCheckbox,
    ModalEditBankAccounts,
    ModalVerifyBankAccount,
    BImg,
    BTooltip,
    IAmIcon,
  },
  directives: {
    Ripple,
  },
  props: {
    agencyData: {
      type: Object,
      default: () => { },
    },
  },
  setup(props) {
    const { toastError, toastSuccess } = useToast()

    const agencyId = props.agencyData.id
    const tableColumns = [
      'bankId',
      'branch',
      'bankAccountNo',
      'bankAccountName',
      'isDefault',
      'isAuto',
      'status',
      'action',
    ]

    const bankAccountId = ref(null)
    const listBank = computed(() => store.getters['userStore/getListBank'])
    const bankAccount = ref({})
    const bankAccountsList = ref()
    const loading = ref(false)

    async function fetchBankAccounts() {
      loading.value = true
      await store.dispatch('userStore/fetchListBank')
      await store.dispatch('userStore/fetchMeData')
      const { data } = await apiBankAccount.getBankAccounts()
      bankAccountsList.value = data.items
      loading.value = false
    }

    // verify bank account

    async function verifyBankAccount(bankAcc, event) {
      bankAccount.value = bankAcc
      this.$root.$emit('bv::show::modal', 'modal-verify-bank-accounts', event)
    }

    // update isDefault bank account

    function handleSwitchDefault(item) {
      if (item.status !== 'CONFIRMED') return
      this.$bvModal
        .msgBoxConfirm(this.$t('modal.changeStatus'), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(async value => {
          if (value) {
            if (item.isDefault) {
              toastError({
                title: 'messagesList.error',
                content: 'messagesList.settingAccount.cantSetDefaultBank',
              })
            } else {
              loading.value = true
              try {
                await apiBankAccount.setDefaultBank(item.id)
                  .then(() => {
                    fetchBankAccounts()
                    toastSuccess({
                      title: 'messagesList.success',
                      content: 'messagesList.settingAccount.setDefaultBankSuccess',
                    })
                  }).finally(() => {
                    loading.value = false
                  })
              } catch (error) {
                toastError({
                  title: 'messagesList.error',
                  content: 'messagesList.settingAccount.setDefaultBankError',
                })
              }
            }
          }
        })
      //
    }

    // update isAuto bank account

    function handleSwitchAuto(item, bank) {
      if (!bank.isAuto || item.status !== 'CONFIRMED') return
      this.$bvModal
        .msgBoxConfirm(!item.isAuto ? this.$t('modal.setAutoBank', { fee: formatCurrency(item.bank.paymentTxsFee) }) : this.$t('modal.unsetAutoBank'), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(async value => {
          if (value) {
            loading.value = true
            try {
              apiBankAccount.setAutoBank(item.id)
                .then(() => {
                  fetchBankAccounts()
                  toastSuccess({
                    title: 'messagesList.success',
                    content: 'messagesList.settingAccount.setAutoBankSuccess',
                  })
                }).finally(() => {
                  loading.value = false
                })
            } catch (error) {
              toastError({
                title: 'messagesList.error',
                content: 'messagesList.settingAccount.setDefaultBankError',
              })
            }
          }
        })
      //
    }

    // delete bank account

    function confirmDelete(item, event) {
      if (item.isDefault) {
        toastError({
          title: 'messagesList.error',
          content: 'Không thể xoá tài khoản mặc định!',
        })
        return
      }
      const titleVNode = this.$createElement('div', { domProps: { innerHTML: '<span class="font-weight-bolder">XÁC NHẬN XOÁ</span>' } })
      const titleMsg = this.$createElement('div', { domProps: { innerHTML: 'Bạn chắc chắn muốn xoá tài khoản ngân hàng này?' } })
      this.$bvModal
        .msgBoxConfirm([titleMsg], {
          title: [titleVNode],
          size: 'sm',
          okVariant: 'info',
          okTitle: 'Xác nhận',
          cancelTitle: 'Huỷ',
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(async value => {
          if (value) {
            this.$bvModal.show('modal-api-loading')

            await apiBankAccount.removeBankAccount(item.id).then(() => {
              if (item.isAuto) {
                bankAccount.value = item
                this.$root.$emit('bv::show::modal', 'modal-verify-bank-accounts', event)
              }
            })
            toastSuccess({
              title: 'messagesList.success',
              content: 'messagesList.settingAccount.deleteBankAccountSuccess',
            })
            fetchBankAccounts()
          }
        }).catch(() => {
          toastError({
            title: 'messagesList.error',
            content: 'messagesList.settingAccount.deleteBankAccountError',
          })
        }).finally(() => {
          this.$bvModal.hide('modal-api-loading')
        })
    }

    // add bank account

    function handleAddBankAccount() {
      this.$bvModal.show('modal-add-bank-accounts')
    }

    // edit bank account

    async function editBankAccount(id) {
      bankAccountId.value = id
      this.$bvModal.show('modal-edit-bank-accounts')
    }

    // get bankInfo by id
    const { findBank } = useBankAccounts()

    function resolveBankInfo(bankId) {
      const bankInfo = findBank(bankId)
      return bankInfo ? { bankName: `${bankInfo.shortName} (${bankInfo.code})`, bankLogo: bankInfo.logo } : { bankName: bankId }
    }

    onMounted(async () => {
      fetchBankAccounts()
    })

    return {
      tableColumns,
      bankAccountsList,
      agencyId,
      loading,
      handleAddBankAccount,
      fetchBankAccounts,
      handleSwitchDefault,
      confirmDelete,
      editBankAccount,
      bankAccountId,
      resolveBankInfo,
      bankAccount,
      verifyBankAccount,
      listBank,
      handleSwitchAuto,
    }
  },
}
</script>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ValidationObserver', {
    ref: "refFormObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid;
        return [_c('BModal', {
          attrs: {
            "id": "modal-add-bank-accounts",
            "title": _vm.$t('bankAccount.addBankAccount'),
            "title-class": "text-airline font-medium-4 font-weight-bolder",
            "centered": "",
            "body-class": "px-75",
            "size": "lg",
            "no-close-on-backdrop": ""
          },
          on: {
            "show": _vm.showHandle
          },
          scopedSlots: _vm._u([{
            key: "modal-footer",
            fn: function fn(_ref2) {
              var cancel = _ref2.cancel;
              return [_c('BButton', {
                staticClass: "center rounded-pill",
                attrs: {
                  "variant": "outline-secondary"
                },
                on: {
                  "click": function click($event) {
                    return cancel();
                  }
                }
              }, [_vm._v(" " + _vm._s(_vm.$t('bankAccount.cancel')) + " ")]), _c('BButton', {
                directives: [{
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: 'rgba(113, 102, 240, 0.15)',
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: {
                    "400": true
                  }
                }],
                staticClass: "btn-gradient border-right-0",
                attrs: {
                  "pill": "",
                  "disabled": invalid
                },
                on: {
                  "click": _vm.handleAdd
                }
              }, [_c('div', {
                staticClass: "d-flex-center px-25"
              }, [_vm._v(" " + _vm._s(_vm.$t('bankAccount.add')) + " ")])])];
            }
          }], null, true)
        }, [_c('div', [_c('ValidationProvider', {
          attrs: {
            "name": _vm.$t('bankAccount.bankId'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('BFormGroup', {
                attrs: {
                  "label-for": "bankId"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "text-nowrap"
                    }, [_vm._v(" " + _vm._s(_vm.$t('bankAccount.bankId')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('v-select', {
                attrs: {
                  "id": "bankId",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "label": "code",
                  "options": _vm.bankListOptions,
                  "filterable": false,
                  "searchable": "",
                  "reduce": function reduce(val) {
                    return val.id;
                  },
                  "placeholder": _vm.$t('bankAccount.ph.bankId'),
                  "clearable": false
                },
                on: {
                  "open": _vm.openBankListHandle,
                  "search": _vm.searchBankListHandle,
                  "option:selected": function optionSelected($event) {
                    return _vm.selectedBank($event);
                  }
                },
                scopedSlots: _vm._u([{
                  key: "option",
                  fn: function fn(data) {
                    return [_c('div', {
                      staticClass: "d-flex-center justify-content-start"
                    }, [_c('BImg', {
                      attrs: {
                        "src": data.logo,
                        "width": "50"
                      }
                    }), _c('span', [_vm._v(" " + _vm._s("".concat(data.shortName, " (").concat(data.code, ") - ").concat(data.name, ")")) + " ")])], 1)];
                  }
                }, {
                  key: "selected-option",
                  fn: function fn(data) {
                    return [_c('div', {
                      staticClass: "d-flex-center justify-content-start"
                    }, [_c('BImg', {
                      attrs: {
                        "src": data.logo,
                        "width": "50"
                      }
                    }), _c('span', [_vm._v(" " + _vm._s("".concat(data.shortName, " (").concat(data.code, ") - ").concat(data.name, ")")) + " ")])], 1)];
                  }
                }, {
                  key: "spinner",
                  fn: function fn(_ref3) {
                    var loading = _ref3.loading;
                    return [loading ? _c('div', {
                      staticClass: "vs__spinner",
                      staticStyle: {
                        "border-left-color": "rgba(88, 151, 251, 0.71)"
                      }
                    }) : _vm._e()];
                  }
                }, {
                  key: "no-options",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.bankAccountToAdd.bankId,
                  callback: function callback($$v) {
                    _vm.$set(_vm.bankAccountToAdd, "bankId", $$v);
                  },
                  expression: "bankAccountToAdd.bankId"
                }
              }), _c('BFormInvalidFeedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        }), _c('ValidationProvider', {
          attrs: {
            "name": _vm.$t('bankAccount.bankAccountNo'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('BFormGroup', {
                attrs: {
                  "label-for": "bankAccountNo"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "text-nowrap"
                    }, [_vm._v(" " + _vm._s(_vm.$t('bankAccount.bankAccountNo')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('BFormInput', {
                attrs: {
                  "id": "bankAccountNo",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "placeholder": _vm.$t('bankAccount.ph.bankAccountNo')
                },
                on: {
                  "blur": _vm.searchBankHolder
                },
                model: {
                  value: _vm.bankAccountToAdd.bankAccountNo,
                  callback: function callback($$v) {
                    _vm.$set(_vm.bankAccountToAdd, "bankAccountNo", typeof $$v === 'string' ? $$v.trim() : $$v);
                  },
                  expression: "bankAccountToAdd.bankAccountNo"
                }
              }), _c('BFormInvalidFeedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        }), _c('ValidationProvider', {
          attrs: {
            "name": _vm.$t('bankAccount.bankAccountName'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('BFormGroup', {
                attrs: {
                  "label-for": "bankAccountName"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "text-nowrap"
                    }, [_vm._v(" " + _vm._s(_vm.$t('bankAccount.bankAccountName')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('BFormInput', {
                attrs: {
                  "id": "bankAccountName",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "lazy-formatter": "",
                  "formatter": _vm.removeAccentsUpperCaseFormatter,
                  "placeholder": _vm.$t('bankAccount.ph.bankAccountName')
                },
                model: {
                  value: _vm.bankAccountToAdd.bankAccountName,
                  callback: function callback($$v) {
                    _vm.$set(_vm.bankAccountToAdd, "bankAccountName", $$v);
                  },
                  expression: "bankAccountToAdd.bankAccountName"
                }
              }), _c('BFormInvalidFeedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        }), _c('ValidationProvider', {
          attrs: {
            "name": _vm.$t('bankAccount.branch'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('BFormGroup', {
                attrs: {
                  "label-for": "branch"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "text-nowrap"
                    }, [_vm._v(" " + _vm._s(_vm.$t('bankAccount.branch')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('BFormInput', {
                attrs: {
                  "id": "branch",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "lazy-formatter": "",
                  "formatter": _vm.removeAccentsUpperCaseFormatter,
                  "placeholder": _vm.$t('bankAccount.ph.branch')
                },
                model: {
                  value: _vm.bankAccountToAdd.branch,
                  callback: function callback($$v) {
                    _vm.$set(_vm.bankAccountToAdd, "branch", $$v);
                  },
                  expression: "bankAccountToAdd.branch"
                }
              }), _c('BFormInvalidFeedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _vm.bankAccountToAdd.isAuto ? [_c('ValidationProvider', {
          attrs: {
            "name": _vm.$t('bankAccount.identificationNumber'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('BFormGroup', {
                attrs: {
                  "label-for": "identificationNumber"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "text-nowrap"
                    }, [_vm._v(" " + _vm._s(_vm.$t('bankAccount.identificationNumber')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('BFormInput', {
                attrs: {
                  "id": "identificationNumber",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "lazy-formatter": "",
                  "formatter": _vm.removeAccentsUpperCaseFormatter,
                  "placeholder": _vm.$t('bankAccount.ph.identificationNumber')
                },
                model: {
                  value: _vm.bankAccountToAdd.identificationNumber,
                  callback: function callback($$v) {
                    _vm.$set(_vm.bankAccountToAdd, "identificationNumber", $$v);
                  },
                  expression: "bankAccountToAdd.identificationNumber"
                }
              }), _c('BFormInvalidFeedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        }), _c('ValidationProvider', {
          attrs: {
            "name": _vm.$t('bankAccount.phoneNumber'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('BFormGroup', {
                attrs: {
                  "label-for": "phoneNumber"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "text-nowrap"
                    }, [_vm._v(" " + _vm._s(_vm.$t('bankAccount.phoneNumber')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('BFormInput', {
                attrs: {
                  "id": "phoneNumber",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "lazy-formatter": "",
                  "formatter": _vm.removeAccentsUpperCaseFormatter,
                  "placeholder": _vm.$t('bankAccount.ph.bankAccountName')
                },
                model: {
                  value: _vm.bankAccountToAdd.phoneNumber,
                  callback: function callback($$v) {
                    _vm.$set(_vm.bankAccountToAdd, "phoneNumber", $$v);
                  },
                  expression: "bankAccountToAdd.phoneNumber"
                }
              }), _c('BFormInvalidFeedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })] : _vm._e(), !_vm.bankAccountToAdd.isAuto ? _c('div', [_c('ValidationProvider', {
          attrs: {
            "name": _vm.$t('bankAccount.isDefault'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('BFormGroup', {
                attrs: {
                  "label-for": "isDefault"
                }
              }, [_c('BFormCheckbox', {
                attrs: {
                  "id": "isDefault",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "lazy-formatter": "",
                  "switch": "",
                  "placeholder": _vm.$t('bankAccount.isDefault')
                },
                model: {
                  value: _vm.bankAccountToAdd.isDefault,
                  callback: function callback($$v) {
                    _vm.$set(_vm.bankAccountToAdd, "isDefault", $$v);
                  },
                  expression: "bankAccountToAdd.isDefault"
                }
              }, [_c('div', {
                staticClass: "text-nowrap"
              }, [_vm._v(" " + _vm._s(_vm.$t('bankAccount.isDefault')) + " ")])]), _c('BFormInvalidFeedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1) : _vm._e(), _vm.bankSelected && _vm.bankSelected.isAuto ? _c('div', [_c('ValidationProvider', {
          attrs: {
            "name": _vm.$t('bankAccount.isDefault'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('BFormGroup', {
                attrs: {
                  "label-for": "isAuto"
                }
              }, [_c('BFormCheckbox', {
                attrs: {
                  "id": "isAuto",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "lazy-formatter": "",
                  "switch": "",
                  "placeholder": _vm.$t('bankAccount.isAuto')
                },
                model: {
                  value: _vm.bankAccountToAdd.isAuto,
                  callback: function callback($$v) {
                    _vm.$set(_vm.bankAccountToAdd, "isAuto", $$v);
                  },
                  expression: "bankAccountToAdd.isAuto"
                }
              }, [_c('div', {
                staticClass: "text-nowrap"
              }, [_vm._v(" " + _vm._s(_vm.$t('bankAccount.isAuto')) + " ")])]), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(" " + _vm._s(_vm.$t('modal.setAutoBank', {
                fee: _vm.formatCurrency(_vm.bankSelected.paymentTxsFee)
              })) + " ")]), _c('BFormInvalidFeedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1) : _vm._e()], 2)];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ValidationObserver', {
    ref: "refFormObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid;
        return [_c('BModal', {
          attrs: {
            "id": "modal-edit-bank-accounts",
            "title": _vm.$t('agency.bankAccountsList.editBankAccount'),
            "title-class": "text-airline font-medium-4 font-weight-bolder",
            "centered": "",
            "body-class": "px-75",
            "size": "lg",
            "no-close-on-backdrop": ""
          },
          on: {
            "show": _vm.showHandle
          },
          scopedSlots: _vm._u([{
            key: "modal-footer",
            fn: function fn(_ref2) {
              var cancel = _ref2.cancel;
              return [_c('BButton', {
                staticClass: "center rounded-pill",
                attrs: {
                  "variant": "outline-secondary"
                },
                on: {
                  "click": function click($event) {
                    return cancel();
                  }
                }
              }, [_vm._v(" " + _vm._s(_vm.$t('bankAccount.cancel')) + " ")]), _c('BButton', {
                directives: [{
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: 'rgba(113, 102, 240, 0.15)',
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: {
                    "400": true
                  }
                }],
                staticClass: "btn-gradient border-right-0",
                attrs: {
                  "pill": "",
                  "disabled": invalid
                },
                on: {
                  "click": _vm.handleEdit
                }
              }, [_c('div', {
                staticClass: "d-flex-center px-25"
              }, [_vm._v(" " + _vm._s(_vm.$t('bankAccount.edit')) + " ")])])];
            }
          }], null, true)
        }, [_c('IAmOverlay', {
          attrs: {
            "loading": _vm.loading
          }
        }, [_c('div', [_c('ValidationProvider', {
          attrs: {
            "name": _vm.$t('bankAccount.branch'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('BFormGroup', {
                attrs: {
                  "label-for": "branch"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "text-nowrap"
                    }, [_vm._v(" " + _vm._s(_vm.$t('bankAccount.branch')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('BFormInput', {
                attrs: {
                  "id": "branch",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "lazy-formatter": "",
                  "formatter": _vm.removeAccentsUpperCaseFormatter,
                  "placeholder": _vm.$t('bankAccount.ph.branch')
                },
                model: {
                  value: _vm.dataToEdit.branch,
                  callback: function callback($$v) {
                    _vm.$set(_vm.dataToEdit, "branch", $$v);
                  },
                  expression: "dataToEdit.branch"
                }
              }), _c('BFormInvalidFeedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _vm.dataToEdit.isAuto ? [_c('ValidationProvider', {
          attrs: {
            "name": _vm.$t('bankAccount.identificationNumber'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('BFormGroup', {
                attrs: {
                  "label-for": "identificationNumber"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "text-nowrap"
                    }, [_vm._v(" " + _vm._s(_vm.$t('bankAccount.identificationNumber')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('BFormInput', {
                attrs: {
                  "id": "identificationNumber",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "lazy-formatter": "",
                  "formatter": _vm.removeAccentsUpperCaseFormatter,
                  "placeholder": _vm.$t('bankAccount.ph.identificationNumber')
                },
                model: {
                  value: _vm.dataToEdit.identificationNumber,
                  callback: function callback($$v) {
                    _vm.$set(_vm.dataToEdit, "identificationNumber", $$v);
                  },
                  expression: "dataToEdit.identificationNumber"
                }
              }), _c('BFormInvalidFeedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        }), _c('ValidationProvider', {
          attrs: {
            "name": _vm.$t('bankAccount.phoneNumber'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('BFormGroup', {
                attrs: {
                  "label-for": "phoneNumber"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "text-nowrap"
                    }, [_vm._v(" " + _vm._s(_vm.$t('bankAccount.phoneNumber')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('BFormInput', {
                attrs: {
                  "id": "phoneNumber",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "lazy-formatter": "",
                  "formatter": _vm.removeAccentsUpperCaseFormatter,
                  "placeholder": _vm.$t('bankAccount.ph.bankAccountName')
                },
                model: {
                  value: _vm.dataToEdit.phoneNumber,
                  callback: function callback($$v) {
                    _vm.$set(_vm.dataToEdit, "phoneNumber", $$v);
                  },
                  expression: "dataToEdit.phoneNumber"
                }
              }), _c('BFormInvalidFeedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })] : _vm._e(), !_vm.dataToEdit.isAuto ? _c('div', [_c('ValidationProvider', {
          attrs: {
            "name": _vm.$t('bankAccount.isDefault'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('BFormGroup', {
                attrs: {
                  "label-for": "isDefault"
                }
              }, [_c('BFormCheckbox', {
                attrs: {
                  "id": "isDefault",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "lazy-formatter": "",
                  "switch": "",
                  "placeholder": _vm.$t('bankAccount.isDefault')
                },
                model: {
                  value: _vm.dataToEdit.isDefault,
                  callback: function callback($$v) {
                    _vm.$set(_vm.dataToEdit, "isDefault", $$v);
                  },
                  expression: "dataToEdit.isDefault"
                }
              }, [_c('div', {
                staticClass: "text-nowrap"
              }, [_vm._v(" " + _vm._s(_vm.$t('bankAccount.isDefault')) + " ")])]), _c('BFormInvalidFeedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1) : _vm._e(), _vm.bankSelected && _vm.bankSelected.isAuto ? _c('div', [_c('ValidationProvider', {
          attrs: {
            "name": _vm.$t('bankAccount.isDefault'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('BFormGroup', {
                attrs: {
                  "label-for": "isAuto"
                }
              }, [_c('BFormCheckbox', {
                attrs: {
                  "id": "isAuto",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "lazy-formatter": "",
                  "switch": "",
                  "placeholder": _vm.$t('bankAccount.isAuto')
                },
                model: {
                  value: _vm.dataToEdit.isAuto,
                  callback: function callback($$v) {
                    _vm.$set(_vm.dataToEdit, "isAuto", $$v);
                  },
                  expression: "dataToEdit.isAuto"
                }
              }, [_c('div', {
                staticClass: "text-nowrap"
              }, [_vm._v(" " + _vm._s(_vm.$t('bankAccount.isAuto')) + " ")])]), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(" " + _vm._s(_vm.$t('modal.setAutoBank', {
                fee: _vm.formatCurrency(_vm.bankSelected.paymentTxsFee)
              })) + " ")]), _c('BFormInvalidFeedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1) : _vm._e()], 2)], 1)];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }